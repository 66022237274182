import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import scrollToElement from 'scroll-to-element'

import {
  isInternalUrl,
  splitArrayEvenly,
  splitDestinationsIntoGroups,
  getDestinationPartsValue
} from '../utils/helpers'
import HubSpotForm from '../components/HubSpotForm'
import Form from '../components/Form' // fallback
import Map from '../components/Map'
import logo1 from '../assets/images/logos/Logo_Reisegarantie_Grau.svg'
import logo2 from '../assets/images/logos/Logo_SRV_Grau.svg'
import logo3 from '../assets/images/logos/Logo_myclimate_Grau.svg'
import logo4 from '../assets/images/logos/Logo_TPS_Grau.svg'

// Projects
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

let stamp = null

if (env.content?.footer?.stamp) {
  stamp = require(`../assets/images/stamps/${env.homepage_destination}.svg`)
}

const schemaHtml = code => {
  const modifiedCode = code
    .replace('</p><p>', ', ')
    .replace(',</p><p>', ', ')
    .replace(/(<([^>]+)>)/gi, '')
    .replace('&nbsp;', ' ')
  return modifiedCode
}

const getFooterLinkData = link => {
  return link?.value?.item?.value || null
}

const renderFooterDestinations = (destinations, langSlug, title, parts) => {
  const columns = splitArrayEvenly(destinations, parts)

  return (
    <div>
      <strong>{title}</strong>
      <div className='row'>
        {columns.map((col, index) => (
          <ul key={index} className='col-xs-6 col-sm-4 col-md-3 col-lg-2'>
            {col.map((destination, index) => {
              let link

              // Change footer link to Knecht original website
              if (
                !env.destinations ||
                _.includes(env.destinations, destination.name.value)
              ) {
                link = '/' + langSlug + destination.slug.value
              } else {
                link = envFile.env['prod'].url + destination.slug.value
              }

              return (
                <li key={index}>
                  {isInternalUrl(link) ? (
                    <Link to={link}>
                      <strong>{destination.name.value.toLowerCase()}</strong>
                      reisen
                    </Link>
                  ) : (
                    <a href={link}>
                      <strong>{destination.name.value.toLowerCase()}</strong>
                      reisen
                    </a>
                  )}
                </li>
              )
            })}
          </ul>
        ))}
      </div>
    </div>
  )
}

const renderFooterLinks = (
  footerLinks,
  footerLinksSpacer,
  contactPagePath,
  destinationSlug,
  langSlug
) => {
  if (!footerLinks) {
    return null
  }

  let parsedLinks = []
  for (let i = 0; i < footerLinks.length; i++) {
    const link = getFooterLinkData(footerLinks[i])
    parsedLinks.push(link)
  }

  const spacerIndex = parseInt(footerLinksSpacer)
  if (spacerIndex > 0 && spacerIndex < parsedLinks.length) {
    let parts = []
    parts[0] = parsedLinks.slice(0, spacerIndex)
    parts[1] = parsedLinks.slice(spacerIndex)

    return parts.map((links, index) =>
      renderFooterLinksSection(
        links,
        contactPagePath,
        destinationSlug,
        langSlug,
        index
      )
    )
  } else {
    return renderFooterLinksSection(
      parsedLinks,
      contactPagePath,
      destinationSlug,
      langSlug,
      0
    )
  }
}

const removeTrailingSlash = str => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

const renderFooterLinksSection = (
  links,
  contactPagePath,
  destinationSlug,
  langSlug,
  parentIndex
) => {
  const urlStart = langSlug ? `/${removeTrailingSlash(langSlug)}` : ''
  return (
    <ul key={parentIndex}>
      {links &&
        links.map((link, index) => {
          const label = link?.label?.value
          if (!link || !label) {
            return null
          }

          const {
            isExternal: { value: isExternal },
            isHighlighted: { value: isHighlighted }
          } = link

          const externalUrl = link?.externalUrl?.value
          const internalUrl = link?.internalUrl?.value
          const linkType = link?.linkType?.value

          if (contactPagePath && linkType === 'contact') {
            return (
              <li
                className={`${isHighlighted ? 'highlighted' : ''}`}
                key={index}
              >
                <Link to={contactPagePath}>{label}</Link>
              </li>
            )
          }

          if (isExternal) {
            return (
              externalUrl && (
                <li
                  className={`${isHighlighted ? 'highlighted' : ''}`}
                  key={index}
                >
                  <a href={externalUrl}>{label}</a>
                </li>
              )
            )
          } else {
            if (!internalUrl) {
              return null
            }
            const { level2, level3, level4 } = internalUrl

            const urlParts = [
              destinationSlug,
              level2 && level2.value,
              level3 && level3.value,
              level4 && level4.value
              // level5 && level5.value
            ]

            // destination e.g. 'knecht' is homepage, remove it from URL
            if (urlParts[0] === env.homepage_destination) {
              urlParts.shift()
            }

            const internalLink = '/' + urlParts.filter(Boolean).join('/')
            return (
              <li
                className={`${isHighlighted ? 'highlighted' : ''}`}
                key={index}
              >
                <Link to={`${urlStart}${internalLink}`}>{label}</Link>
              </li>
            )
          }
        })}
    </ul>
  )
}

const Footer = ({ pageContext }) => {
  const {
    destination,
    destinations,
    knechtDestination,
    mainDestination,
    newsletterForm,
    langSlug,
    page,
    lang,
    hsForms
  } = pageContext
  let { url } = pageContext
  const [parts, setParts] = useState(getDestinationPartsValue())

  useEffect(() => {
    const handleResize = () => setParts(getDestinationPartsValue())

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isHubSpotForm =
    newsletterForm?.snippet && newsletterForm.snippet ? true : false // fallback

  // get all info about the current destination
  let currentDestination = destinations.filter(d => {
    return d.cockpitId === destination?.cockpitId
  })

  if (Array.isArray(currentDestination)) {
    currentDestination = currentDestination[0]
  }

  let footerLinks = currentDestination?.footerLinks?.value
  let footerLinksSpacer = currentDestination?.footerLinksSpacer?.value
  if (!footerLinks) {
    // fallback to website main/home destination
    footerLinks = knechtDestination?.footerLinks?.value
    footerLinksSpacer = knechtDestination?.footerLinksSpacer?.value
  }

  const destinationGroups = splitDestinationsIntoGroups(destinations)

  const destinationSlug = destination?.slug?.value
  // const color = destination?.color?.value
  const address = destination?.address?.value
  const hours = destination?.hours?.value
  // For subdomains, phones are same for all destinations
  const phone = env.phone_number || destination?.phone?.value
  const email = destination?.emailFormSubmissions?.value
  const [siteName, siteDomain] = email.split('@')
  const emailLinkRef = useRef(null)

  useEffect(() => {
    const emailLink = emailLinkRef.current

    // Attempt JS email obfuscation: dynamically set mailto on click
    const handleClick = event => {
      event.preventDefault()
      const mailtoLink = `mailto:${siteName}@${siteDomain}`
      window.location.href = mailtoLink
    }

    if (emailLink) {
      emailLink.addEventListener('click', handleClick)
    }

    // Cleanup the event listener
    return () => {
      if (emailLink) {
        emailLink.removeEventListener('click', handleClick)
      }
    }
  }, [siteName, siteDomain])

  const websiteName =
    destination?.websiteName?.value || knechtDestination.name?.value

  // const isKnechtPage = destinationSlug === env.homepage_destination // homepage and its pages
  // const urlStart = !isKnechtPage
  //   ? `/${langSlug}${destinationSlug}/`
  //   : '/' + langSlug

  const contactPage = page?.contactPage?.value
  // let contactPagePath = `${urlStart}kontakt`
  let contactPagePath = null
  if (contactPage) {
    // const { level2, level3, level4, level5, destination } = contactPage
    const { level2, level3, level4, level5, destinations } = contactPage
    let contactPageDstSlug
    if (destinations.value.length > 1) {
      const currentDst = destinations.value.filter(
        d => d.slug.value === destinationSlug
      )
      if (currentDst && currentDst.length > 0) {
        contactPageDstSlug = currentDst[0].slug.value
      } else {
        contactPageDstSlug = destinations.value[0].slug.value
      }
    } else {
      contactPageDstSlug = destinations.value[0].slug.value
    }
    const urlParts = [
      contactPageDstSlug, // destination.value.slug.value,
      level2 && level2.value,
      level3 && level3.value,
      level4 && level4.value,
      level5 && level5.value
    ]

    // destination 'knecht' is homepage, remove it from URL
    if (urlParts[0] === env.homepage_destination) {
      urlParts.shift()
    }
    contactPagePath = '/' + urlParts.filter(Boolean).join('/')
  }

  const mapCenter = env.mapCenter || [47.46883000992906, 8.212500214576721]
  // const feedbackPrefix =
  //   env.homepage_destination === 'glur' ? 'skandinavien/' : ''
  const jobsLink = env.jobsLink
  if (!jobsLink.isExternal) {
    jobsLink.link = `${langSlug}${jobsLink.link}`
  }

  // Search if in url is / if not add
  if (url.charAt(0) !== '/') {
    url = '/' + url
  }

  // const getFeedbackPrefix = () => {
  //   if (feedbackPrefix.length > 0 && urlStart.endsWith(feedbackPrefix)) {
  //     return ''
  //   }

  //   return feedbackPrefix
  // }

  // Integration of schema address for location (standorte)
  // let contactPoint = []
  // Define contact point if exists + strip html tags
  // if (address) {
  //   contactPoint = [
  //     {
  //       '@type': 'ContactPoint',
  //       areaServed: address.replace(/(<([^>]+)>)/gi, ''),
  //       hoursAvailable: hours.replace(/(<([^>]+)>)/gi, '')
  //     }
  //   ]
  // }

  const travelAgencyOrgName =
    env?.schemaOrgData?.travelAgency?.name || websiteName
  const websiteOrgName = env?.schemaOrgData?.website?.name || websiteName

  return (
    <>
      {knechtDestination && (
        <Helmet>
          <script type='application/ld+json'>
            {`{
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "openingHours": "${schemaHtml(knechtDestination.hours?.value)}",
            "image": "${pageContext?.host}/images/knecht-reisen.png",
            "name": "${travelAgencyOrgName}",
            "address": "${schemaHtml(knechtDestination.address?.value)}",
            "telephone": "${knechtDestination.phone?.value}",
            "sameAs": [
              "https://www.facebook.com/knechtreisen/",
              "https://www.youtube.com/channel/UCG-y4-G6r--PDqf0R5jLxGw",
              "https://www.instagram.com/knechtreisen/?hl=de",
              "https://www.linkedin.com/company/knecht-reisen"
              ]
          }`}
          </script>
          <script type='application/ld+json'>
            {`{
              "@context" : "https://schema.org",
              "@type" : "WebSite",
              "name": "${websiteOrgName}",
              "url" : "${env?.url}"
            }`}
          </script>
        </Helmet>
      )}

      <footer>
        <div className='container'>
          <div className='footer-logos footer-box'>
            <div className='footer-col'>
              {stamp && (
                <Link to={`/${langSlug}`}>
                  <img
                    className='stamp stamp-knecht stamp-footer'
                    src={stamp}
                    alt='homepage stamp'
                    title='homepage stamp'
                    loading='lazy'
                    height='30'
                    width='100'
                  />
                </Link>
              )}
              {/* <span
                className='headline'
                dangerouslySetInnerHTML={{
                  __html: env.content?.footer?.content
                }}
              />
              <br />
              <a href={'mailto:' + env?.content?.footer?.email}>
                {env?.content?.footer?.email}
              </a> */}
            </div>

            <div className='footer-col logo-container'>
              <img
                src={logo1}
                alt='reisegarantie logo'
                title='reisegarantie'
                loading='lazy'
                height='30'
                width='100'
              />
              <img
                src={logo2}
                alt='srv fsv logo'
                title='srv fsv'
                loading='lazy'
                height='30'
                width='100'
              />
              <img
                src={logo3}
                alt='myclimate logo'
                title='myclimate'
                loading='lazy'
                height='30'
                width='100'
              />
              <img
                src={logo4}
                alt='tps logo'
                title='tps'
                loading='lazy'
                height='30'
                width='100'
              />
            </div>
          </div>

          <div className='footer-middle footer-row'>
            <div className='footer-col'>
              <div className='footer-sub-box sub-box-1'>
                <strong>Adresse</strong>
                <div dangerouslySetInnerHTML={{ __html: address }} />
              </div>
              <div className='footer-sub-box sub-box-2'>
                <strong>E-Mail</strong>
                {/* Attempt CSS email obfuscation: add hidden noise */}
                <div>
                  <a href='/' ref={emailLinkRef}>
                    <b
                      style={{
                        display: 'inline-block',
                        width: 0,
                        height: 0,
                        overflow: 'hidden'
                      }}
                    >
                      {siteDomain}.
                    </b>
                    {siteName}@
                    <b
                      style={{
                        display: 'inline-block',
                        width: 0,
                        height: 0,
                        overflow: 'hidden'
                      }}
                    >
                      .{siteDomain + '.' + siteName}
                    </b>
                    {siteDomain}
                  </a>
                </div>
              </div>
              <div className='footer-sub-box sub-box-3 footer-socials'>
                <strong>Follow us</strong>
                <div className='footer-socials-icons'>
                  <a
                    // href='https://www.facebook.com/knechtreisen/'
                    href={
                      currentDestination?.facebookUrl?.value ||
                      mainDestination?.facebookUrl?.value
                    }
                    aria-label='Facebook'
                    rel='noopener noreferrer nofollow'
                    target='_blank'
                  >
                    <i className='ki ki-facebook'></i>
                  </a>
                  <a
                    // href='https://www.instagram.com/knechtreisen/?hl=de'
                    href={
                      currentDestination?.instagramUrl?.value ||
                      mainDestination?.instagramUrl?.value
                    }
                    aria-label='Instagram'
                    rel='noopener noreferrer nofollow'
                    target='_blank'
                  >
                    <i className='ki ki-instagram'></i>
                  </a>
                  <a
                    // href='https://www.youtube.com/channel/UCG-y4-G6r--PDqf0R5jLxGw'
                    href={
                      currentDestination?.youtubeUrl?.value ||
                      mainDestination?.youtubeUrl?.value
                    }
                    aria-label='Youtube'
                    rel='noopener noreferrer nofollow'
                    target='_blank'
                  >
                    <i className='ki ki-youtube-play'></i>
                  </a>
                </div>
              </div>
            </div>

            <div className='footer-col'>
              <div className='footer-sub-box sub-box-1'>
                <strong>Öffnungszeiten</strong>
                <div dangerouslySetInnerHTML={{ __html: hours }} />
              </div>
              <div className='footer-sub-box sub-box-2'>
                <strong>Telefon</strong>
                {phone && (
                  <div>
                    <a href={`tel:${phone}`}>{phone}</a>
                  </div>
                )}
              </div>
              <div
                id='newsletter'
                className='footer-sub-box sub-box-3 newsletter'
              >
                <strong>
                  <span>Newsletter</span>
                </strong>

                <div className='form-wrapper'>
                  {isHubSpotForm && (
                    <HubSpotForm
                      settings={newsletterForm}
                      defaultValues={{
                        newsletter: true
                      }}
                      allHsForms={hsForms}
                    />
                  )}
                  {!isHubSpotForm && (
                    <Form
                      settings={{
                        formData: newsletterForm
                      }}
                      defaultValues={{
                        newsletter: true
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className='footer-col'>
              <div className='footer-pages footer-box footer-sub-box'>
                {renderFooterLinks(
                  footerLinks,
                  footerLinksSpacer,
                  contactPagePath,
                  destinationSlug,
                  langSlug
                )}
                {/* <ul>
                  <li>
                    <Link to={`${urlStart}ueber-uns`}>Über uns</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}spezialisten-team`}>
                      Spezialisten-Team
                    </Link>
                  </li>
                  <li>
                    <Link to={contactPagePath}>Anfrage senden</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}feedback`}>Feedbacks</Link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to={`${urlStart}events`}>Events</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}nachhaltigkeit`}>Nachhaltigkeit</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}datenschutz`}>Datenschutz</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}knecht-reisegruppe`}>
                      Knecht Gruppe
                    </Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}agb`}>AGB</Link>
                  </li>
                  <li>
                    <Link to={`${urlStart}impressum`}>Impressum</Link>
                  </li>
                  {jobsLink?.link && (
                    <li>
                      <Link to={jobsLink?.link}>Jobs</Link>
                    </li>
                  )}
                </ul> */}
              </div>
            </div>
            <div className='footer-col'>
              <div className='footer-map footer-box'>
                <strong>Standort</strong>
                <Map center={mapCenter} zoom={13} height={180} />
              </div>
            </div>
          </div>

          <div className='footer-destinations footer-box'>
            {renderFooterDestinations(
              destinationGroups.destinations,
              langSlug,
              'Reiseziele',
              parts
            )}
            {renderFooterDestinations(
              destinationGroups.categories,
              langSlug,
              'Reisearten',
              parts
            )}
          </div>
        </div>

        <div className='footer-bottom'>
          <button
            className='back-to-top'
            aria-label='Back to top'
            onClick={() => scrollToElement('body', { offset: -220 })}
          >
            <i className='ki ki-angle-up'></i>
          </button>
          <p>&copy; {new Date().getFullYear()} Knecht Reisen</p>
          {env.languages?.displaySwitch && (
            <div className='lang-switcher'>
              {_.map(env.languages.settings, (item, id) => {
                return (
                  <Link
                    index={id}
                    to={`/${item.slug.slice(0, -1)}${url}`}
                    className={lang === id && 'selected'}
                  >
                    {item.name}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </footer>
    </>
  )
}

export default Footer
