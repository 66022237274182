// Language templates
// Key "any" is default name in Cockpit CMS
const languageTemplates = {
  defr: {
    displaySwitch: true,
    cockpitLocales: ['fr'],
    settings: {
      any: {
        primary: true,
        lang: 'de',
        name: 'German',
        slug: 'de/'
      },
      fr: {
        lang: 'fr',
        name: 'French',
        slug: 'fr/'
      }
    }
  },
  de: {
    displaySwitch: false,
    cockpitLocales: ['any'],
    settings: {
      any: {
        primary: true,
        lang: 'de',
        name: 'German',
        slug: 'de/'
      }
    }
  },
  default: {
    displaySwitch: false,
    cockpitLocales: ['any'],
    settings: {
      any: {
        lang: 'de',
        name: 'German',
        slug: ''
      }
    }
  }
}

const cookiebotSettings = {
  glur: 'a951dda6-a8c3-4bd9-883c-fab7f52479ce',
  knechtReisen: '7faa076f-062f-4e1b-90be-574b2ac890c7',
  latinotravel: '3638b898-01fe-4df0-8c61-bdf052b0482e',
  kira: '65990ec5-a0ed-4bd1-83ea-1c8176910345'
}

// Setup Environment
exports.env = {
  prod: {
    mode: 'prod',
    url: 'https://www.knecht-reisen.ch/',
    canonical: 'knecht-reisen.ch',
    homepage_destination: 'knecht',
    cookiebotSettings: cookiebotSettings.knechtReisen,
    languages: languageTemplates.default,
    firebase_config: 'firebase.json',
    special_header: null,
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    excludeDestinations: ['glur'],
    GTM: 'GTM-N9SCNKQ',
    additionalGTM: '',
    recaptcha_key: '6LdxImYeAAAAAHnuLavteFiVjOyRkMJnqtVFShYN',
    jobsLink: {
      link: '/jobs',
      isExternal: false
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        content: '<strong>knecht</strong>reisen',
        email: 'knecht-reisen@knecht-reisen.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Knecht Reisen'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: '4x_BS7LMc0os1ZRagzssZagrEtK6T04ggqQOoT5IcR0',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    schemaOrgData: {
      travelAgency: {
        name: 'Mein Reisespezialist'
      },
      website: {
        name: 'Mein Reisespezialist'
      }
    },
    structuredData: [
      {
        type: 'application/ld+json',
        content: `{
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "Reisespezialist/in 80-100%",
          "description" : "<p>Es erwarten Sie spannende Aufgaben in einem Team von über 160 Mitarbeitenden an 18 Standorten in der ganzen Schweiz. knecht reisen ist als viertgrösster Reiseveranstalter der Schweiz ei   erfolgreiches und gesundes Unternehmen. Wir suchen Reisespezialist*innen und Travel Product Manager für verschiedene Kontinente.</p>",
          "datePosted" : "2022-10-10",
          "validThrough" : "2030-12-31T23:59:59+0000",
          "employmentType" : "CONTRACTOR",
          "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "knecht reisen ag",
              "sameAs" : "https://www.knecht-reisen.ch/jobs",
              "logo" : "https://res.cloudinary.com/kr-reisen/image/upload/v1619595926/knecht/impressum/Knechtreisen_CMYK_Claim_neg_mit_HG_V_iabkna.png"
          },
          "jobLocation": {
          "@type": "Place",
              "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Schwimmbadstrasse 1",
                  "addressLocality": "Windisch",
                  "addressRegion": "AG",
                  "postalCode": "5210",
                  "addressCountry": "CH"
              }
          }
        }`,
        useOnPageUrl: '/jobs'
      },
      {
        type: 'application/ld+json',
        content: `{
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "Quereinstieg in die Reisebranche",
          "description" : "<p>Es erwarten Sie spannende Aufgaben in einem Team von über 160 Mitarbeitenden an 18 Standorten in der ganzen Schweiz. knecht reisen ist als viertgrösster Reiseveranstalter der Schweiz ei   erfolgreiches und gesundes Unternehmen. Wir suchen Reisespezialist*innen und Travel Product Manager für verschiedene Kontinente.</p>",
          "datePosted" : "2022-10-10",
          "validThrough" : "2030-12-31T23:59",
          "employmentType" : "CONTRACTOR",
          "hiringOrganization" : {
              "@type" : "Organization",
              "name" : "knecht reisen ag",
              "sameAs" : "https://www.knecht-reisen.ch/jobs",
              "logo" : "https://res.cloudinary.com/kr-reisen/image/upload/v1619595926/knecht/impressum/Knechtreisen_CMYK_Claim_neg_mit_HG_V_iabkna.png"
          },
          "jobLocation": {
          "@type": "Place",
              "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Schwimmbadstrasse 1",
                  "addressLocality": "Windisch",
                  "addressRegion": "AG",
                  "postalCode": "5210",
                  "addressCountry": "CH"
              }
          }
        }`,
        useOnPageUrl: '/jobs/quereinstieg'
      }
    ]
  },
  staging: {
    mode: 'prod',
    url: 'https://knecht-reisen-staging.firebaseapp.com/',
    domain: 'knecht-reisen-staging.firebaseapp.com',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'knecht',
    cookiebotSettings: cookiebotSettings.knechtReisen,
    languages: languageTemplates.default,
    firebase_config: 'firebase.json',
    special_header: null,
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    excludeDestinations: ['glur'],
    GTM: '',
    additionalGTM: '',
    recaptcha_key: '6LdxImYeAAAAAHnuLavteFiVjOyRkMJnqtVFShYN',
    jobsLink: {
      link: '/jobs',
      isExternal: false
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: 'eddbf76da9e42f1022722c78288b3f'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        content: '<strong>knecht</strong>reisen',
        email: 'knecht-reisen@knecht-reisen.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Knecht Reisen'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: [],
    schemaOrgData: {
      travelAgency: {
        name: 'Mein Reisespezialist'
      },
      website: {
        name: 'Mein Reisespezialist'
      }
    }
  },
  dev: {
    mode: 'dev',
    url: 'http://localhost:8000',
    homepage_destination: 'knecht',
    cookiebotSettings: cookiebotSettings.knechtReisen,
    languages: languageTemplates.default,
    firebase_config: 'firebase.json',
    special_header: null,
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    excludeDestinations: ['glur'],
    GTM: '',
    additionalGTM: '',
    jobsLink: {
      link: '/jobs',
      isExternal: false
    },
    cockpit: {
      url: 'http://localhost:6009',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: ''
    },
    api_endpoints: [
      'http://localhost:6009/roundtrips.json',
      'http://localhost:6009/hotels.json'
    ],
    content: {
      footer: {
        content: '<strong>knecht</strong>reisen',
        email: 'knecht-reisen@knecht-reisen.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Knecht Reisen'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: '4x_BS7LMc0os1ZRagzssZagrEtK6T04ggqQOoT5IcR0',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    structuredData: [],
    schemaOrgData: {
      travelAgency: {
        name: 'Mein Reisespezialist'
      },
      website: {
        name: 'Mein Reisespezialist'
      }
    }
  },
  dev_local: {
    mode: 'dev',
    url: 'http://localhost:8000',
    homepage_destination: 'knecht',
    cookiebotSettings: cookiebotSettings.knechtReisen,
    languages: languageTemplates.default,
    firebase_config: 'firebase.json',
    special_header: null,
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    excludeDestinations: ['glur'],
    GTM: '',
    additionalGTM: '',
    jobsLink: {
      link: '/jobs',
      isExternal: false
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: 'eddbf76da9e42f1022722c78288b3f'
    },
    api_endpoints: [
      'https://cockpitcms.knecht-reisen.ch/roundtrips.json',
      'https://cockpitcms.knecht-reisen.ch/hotels.json'
    ],
    content: {
      footer: {
        content: '<strong>knecht</strong>reisen',
        email: 'knecht-reisen@knecht-reisen.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Knecht Reisen'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: '4x_BS7LMc0os1ZRagzssZagrEtK6T04ggqQOoT5IcR0',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    structuredData: [],
    schemaOrgData: {
      travelAgency: {
        name: 'Mein Reisespezialist'
      },
      website: {
        name: 'Mein Reisespezialist'
      }
    }
  },
  dev_latino: {
    mode: 'dev',
    url: 'http://localhost:8000',
    domain: 'localhost:8000',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'latino',
    cookiebotSettings: cookiebotSettings.latinotravel,
    languages: languageTemplates.default,
    special_header: '<strong>latino</strong>travel',
    firebase_config: 'firebase-latino.json',
    single_color: true,
    phone_number: '+41 62 834 71 21',
    destinations: ['latino', 'zentralamerika', 'südamerika', 'karibik'],
    excludeSubDestinations: {
      latino: [],
      karibik: [
        'anguilla',
        'antigua',
        'bahamas',
        'barbados',
        'dominica',
        'grenada',
        'guadeloupe',
        'martinique',
        'st-lucia',
        'st-barts',
        'st-kitts-nevis',
        'st-martin',
        'grenadinen',
        'trinidad-tobago',
        'turks-caicos',
        'virgin-islands'
      ],
      zentralamerika: ['honduras', 'el-salvador'],
      suedamerika: ['paraguay', 'guyanas']
    },
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/latinotravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: 'http://localhost:6009',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'http://localhost:6009/roundtrips.json',
      'http://localhost:6009/hotels.json'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'latino@latinotravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Latino Travel'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  staging_latino: {
    mode: 'prod',
    url: 'https://latino-travel-staging.firebaseapp.com/',
    domain: 'latino-travel-staging.firebaseapp.com',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'latino',
    cookiebotSettings: cookiebotSettings.latinotravel,
    languages: languageTemplates.default,
    firebase_config: 'firebase-latino.json',
    special_header: '<strong>latino</strong>travel',
    single_color: true,
    phone_number: '+41 62 834 71 21',
    destinations: ['latino', 'zentralamerika', 'südamerika', 'karibik'],
    excludeSubDestinations: {
      latino: [],
      karibik: [
        'anguilla',
        'antigua',
        'bahamas',
        'barbados',
        'dominica',
        'grenada',
        'guadeloupe',
        'martinique',
        'st-lucia',
        'st-barts',
        'st-kitts-nevis',
        'st-martin',
        'grenadinen',
        'trinidad-tobago',
        'turks-caicos',
        'virgin-islands'
      ],
      zentralamerika: ['honduras', 'el-salvador'],
      suedamerika: ['paraguay', 'guyanas']
    },
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    recaptcha_key: '6LfTV0gfAAAAADdxymxL3ovo8lpG5d0Ct57gcWQ8',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/latinotravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: 'eddbf76da9e42f1022722c78288b3f'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'latino@latinotravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Latino Travel'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  prod_latino: {
    mode: 'prod',
    url: 'https://www.latinotravel.ch/',
    domain: 'latinotravel.ch',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'latino',
    cookiebotSettings: cookiebotSettings.latinotravel,
    languages: languageTemplates.default,
    firebase_config: 'firebase-latino.json',
    special_header: '<strong>latino</strong>travel',
    single_color: true,
    phone_number: '+41 62 834 71 21',
    destinations: ['latino', 'zentralamerika', 'südamerika', 'karibik'],
    excludeSubDestinations: {
      latino: [],
      karibik: [
        'anguilla',
        'antigua',
        'bahamas',
        'barbados',
        'dominica',
        'grenada',
        'guadeloupe',
        'martinique',
        'st-lucia',
        'st-barts',
        'st-kitts-nevis',
        'st-martin',
        'grenadinen',
        'trinidad-tobago',
        'turks-caicos',
        'virgin-islands'
      ],
      zentralamerika: ['honduras', 'el-salvador'],
      suedamerika: ['paraguay', 'guyanas']
    },
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: 'GTM-WCZ5PSL',
    additionalGTM: 'GTM-WLQJ7VB',
    recaptcha_key: '6LfTV0gfAAAAADdxymxL3ovo8lpG5d0Ct57gcWQ8',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/latinotravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'latino@latinotravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Latino Travel'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: 'OsfR4-lyFCzn0nEt5y7TpVgSeSd_7s7rRFtBnmkmNpM',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    structuredData: []
  },
  dev_kira: {
    mode: 'dev',
    url: 'http://localhost:8000',
    domain: 'localhost:8000',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'kira',
    cookiebotSettings: cookiebotSettings.kira,
    languages: languageTemplates.de,
    firebase_config: 'firebase-kira.json',
    special_header: '<strong>kira</strong>travel',
    single_color: true,
    phone_number: '+41 56 200 19 00',
    destinations: [
      'kira',
      'russland',
      'zentralasien',
      'china',
      'baltikum',
      'schottland',
      'mongolei',
      'polen',
      'rumänien',
      'tschechien',
      'moldawien',
      'ungarn',
      'ukraine',
      'bulgarien',
      'weissrussland',
      'kaukasus'
    ],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/kiratravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: 'http://localhost:6009',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'http://localhost:6009/roundtrips.json',
      'http://localhost:6009/hotels.json'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reservation@kiratravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Kira Travel'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  staging_kira: {
    mode: 'prod',
    url: 'https://kiratravel-staging.firebaseapp.com/',
    domain: 'kiratravel-staging.firebaseapp.com',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'kira',
    cookiebotSettings: cookiebotSettings.kira,
    languages: languageTemplates.de,
    firebase_config: 'firebase-kira.json',
    special_header: '<strong>kira</strong>travel',
    single_color: true,
    phone_number: '+41 56 200 19 00',
    destinations: [
      'kira',
      'russland',
      'zentralasien',
      'china',
      'baltikum',
      'schottland',
      'mongolei',
      'polen',
      'rumänien',
      'tschechien',
      'moldawien',
      'ungarn',
      'ukraine',
      'bulgarien',
      'weissrussland',
      'kaukasus'
    ],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    recaptcha_key: '6LcBV0gfAAAAALKbVisDg2KRqU9zzXXIw6ooB7ZU',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/kiratravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: 'eddbf76da9e42f1022722c78288b3f'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reservation@kiratravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Kira Travel'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  prod_kira: {
    mode: 'prod',
    url: 'https://www.kiratravel.ch/',
    domain: 'kiratravel.ch',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'kira',
    cookiebotSettings: cookiebotSettings.kira,
    languages: languageTemplates.de,
    firebase_config: 'firebase-kira.json',
    special_header: '<strong>kira</strong>travel',
    single_color: true,
    phone_number: '+41 56 200 19 00',
    destinations: [
      'kira',
      'russland',
      'zentralasien',
      'china',
      'baltikum',
      'schottland',
      'mongolei',
      'polen',
      'rumänien',
      'tschechien',
      'moldawien',
      'ungarn',
      'ukraine',
      'bulgarien',
      'weissrussland',
      'kaukasus'
    ],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: 'GTM-MN2N66J',
    additionalGTM: '',
    recaptcha_key: '6LcBV0gfAAAAALKbVisDg2KRqU9zzXXIw6ooB7ZU',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/kiratravel.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reservation@kiratravel.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Kira Travel'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: 'SCDHWYOwC5D8IxMuRoWgCxEO-fmFRqnjy90-s7BeI8Q',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    structuredData: []
  },
  dev_glur: {
    mode: 'dev',
    url: 'http://localhost:8000',
    domain: 'localhost:8000',
    canonical: 'glur.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'glur',
    cookiebotSettings: cookiebotSettings.glur,
    languages: languageTemplates.default,
    firebase_config: 'firebase-glur.json',
    special_header: '<strong>glur</strong>reisen',
    mapCenter: [47.556604053581175, 7.575727320958552],
    specialistsFilter: ['Basel-Glur-Reisen'],
    single_color: true,
    GTM: '',
    additionalGTM: '',
    phone_number: '+41 61 205 94 94',
    // destinations: ['glur'],
    destinations: ['glur', 'antarktis'],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/glur.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: 'http://localhost:6009',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'http://localhost:6009/roundtrips.json',
      'http://localhost:6009/hotels.json'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reisen@glur.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Glur Reisen'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  staging_glur: {
    mode: 'prod',
    url: 'https://glur-reisen-staging.firebaseapp.com/',
    domain: 'glur-reisen-staging.firebaseapp.com',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'glur',
    cookiebotSettings: cookiebotSettings.glur,
    mapCenter: [47.556604053581175, 7.575727320958552],
    specialistsFilter: ['Basel-Glur-Reisen'],
    firebase_config: 'firebase-glur.json',
    languages: languageTemplates.default,
    special_header: '<strong>glur</strong>reisen',
    single_color: true,
    phone_number: '+41 61 205 94 94',
    // destinations: ['glur'],
    destinations: ['glur', 'antarktis'],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    recaptcha_key: '6Le3VUgfAAAAAH1tFIFP69R-3ePlwLG-rRiRohUA',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/glur.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b',
      previewToken: 'eddbf76da9e42f1022722c78288b3f'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reisen@glur.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Glur Reisen'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  },
  prod_glur: {
    mode: 'prod',
    url: 'https://www.glur.ch/',
    domain: 'www.glur.ch',
    canonical: 'knecht-reisen.ch',
    canonicalWithLangSlug: false,
    homepage_destination: 'glur',
    cookiebotSettings: cookiebotSettings.glur,
    mapCenter: [47.556604053581175, 7.575727320958552],
    specialistsFilter: ['Basel-Glur-Reisen'],
    firebase_config: 'firebase-glur.json',
    languages: languageTemplates.default,
    special_header: '<strong>glur</strong>reisen',
    single_color: true,
    phone_number: '+41 61 205 94 94',
    // destinations: ['glur'],
    destinations: ['glur', 'antarktis'],
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: 'GTM-TL4GZ4J',
    additionalGTM: '',
    recaptcha_key: '6Le3VUgfAAAAAH1tFIFP69R-3ePlwLG-rRiRohUA',
    jobsLink: {
      link:
        'https://www.knecht-reisen.ch/jobs/?utm_medium=programmatic&utm_source=display_prospecting&utm_campaign=%28de%29_%5Econvincing%5E_%7Bthink%7D_/glur.ch%5C_%2Ajul-2022%2A',
      isExternal: true
    },
    cockpit: {
      url: process.env.COCKPIT_CMS_URL || 'https://cockpitcms.knecht-reisen.ch',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'https://json.tourbase.knecht-reisen.ch/roundtrips',
      'https://json.tourbase.knecht-reisen.ch/hotels'
    ],
    content: {
      footer: {
        stamp: true,
        email: 'reisen@glur.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Glur Reisen'
      }
    },
    metaTags: [
      {
        name: 'google-site-verification',
        content: 'uIPbIwE2pLO5wZqPaX0_0pzbLOP4HQc-0uVVZilfc3c',
        onlyOnHomepage: true
      }
    ],
    additionalScripts: [],
    structuredData: []
  },
  serve: {
    url: 'http://localhost:9001',
    policy: [
      {
        userAgent: '*',
        allow: '/'
      }
    ],
    GTM: '',
    additionalGTM: '',
    cockpit: {
      url: 'http://localhost:6009',
      accessToken: '0f3849e8eb25d5bcd80e75d8f27c74',
      accessTokenCatalogs: 'a5c1ae4e8f0a1933e84ec9781ec84b'
    },
    api_endpoints: [
      'http://localhost:6009/roundtrips.json',
      'http://localhost:6009/hotels.json'
    ],
    content: {
      footer: {
        content: '<strong>knecht</strong>reisen',
        email: 'knecht-reisen@knecht-reisen.ch'
      }
    },
    meta: {
      title: {
        defaultSuffix: 'Knecht Reisen'
      }
    },
    metaTags: [],
    additionalScripts: [],
    structuredData: []
  }
}
