/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */

export function isDomAvailable() {
  return (
    typeof window !== 'undefined' &&
    !!window.document &&
    !!window.document.createElement
  )
}

export function splitArrayEvenly(array, n) {
  let result = []

  if (array && Array.isArray(array)) {
    array = array.slice()

    while (array.length) {
      result.push(array.splice(0, Math.ceil(array.length / n--)))
    }
  }

  return result
}

export function chunkArray(myArray, chunkSize) {
  var index = 0
  var arrayLength = myArray ? myArray.length : 0
  var tempArray = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    let myChunk = myArray.slice(index, index + chunkSize)
    tempArray.push(myChunk)
  }

  return tempArray
}

export function germanTimeFormat(time) {
  if (typeof time === 'undefined') {
    return null
  }

  time = time.replace(':', '.')
  return time
}

// splits destination into two groups: proper destinations/categories (Reiseziele/Reisearten)
export const splitDestinationsIntoGroups = destinations => {
  const destinationGroups = {
    destinations: [],
    categories: []
  }
  if (destinations) {
    destinations.forEach(d => {
      if (d.isCategory?.value) {
        destinationGroups.categories.push(d)
      } else {
        destinationGroups.destinations.push(d)
      }
    })
  }
  return destinationGroups
}

const getCurrentDestinationMenuItem = (menuItems, currentPageUrl) => {
  if (menuItems.length > 0) {
    const currentDestinationMenuItem = menuItems?.filter(item => {
      const itemUrl = item?.value?.url?.value
      let sanitisedItemUrl = itemUrl
      if (itemUrl && !itemUrl.startsWith('/')) {
        sanitisedItemUrl = `/${itemUrl}`
      }
      return sanitisedItemUrl === currentPageUrl
    })

    if (currentDestinationMenuItem && currentDestinationMenuItem.length > 0) {
      return currentDestinationMenuItem['0']
    }
  }

  return null
}

export const getDestinationLabel = (
  currentPageUrl,
  isRootPage,
  mainDestinationMenuItems,
  destinationMenuItems
) => {
  if (currentPageUrl === '/') {
    // main/home page
    return ''
  }

  let currentDestinationMenuItem
  if (isRootPage) {
    // process for destination homepage
    currentDestinationMenuItem = getCurrentDestinationMenuItem(
      mainDestinationMenuItems,
      currentPageUrl
    )
  } else {
    currentDestinationMenuItem = recursivelyGetDestinationMenuItems(
      currentPageUrl,
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  return currentDestinationMenuItem
    ? currentDestinationMenuItem?.value?.text?.value
    : ''
}

const recursivelyGetDestinationMenuItems = (
  url,
  mainDestinationMenuItems,
  destinationMenuItems
) => {
  const urlParts = url.split('/').filter(element => element)

  let menuItem
  if (urlParts.length === 1) {
    menuItem = getCurrentDestinationMenuItem(
      mainDestinationMenuItems,
      '/' + urlParts[0]
    )
    return menuItem
  } else {
    menuItem = getCurrentDestinationMenuItem(
      destinationMenuItems,
      '/' + urlParts.join('/')
    )
  }

  if (!menuItem) {
    // remove the last URL part
    urlParts.pop()
    menuItem = recursivelyGetDestinationMenuItems(
      urlParts.join('/'),
      mainDestinationMenuItems,
      destinationMenuItems
    )
  }

  return menuItem
}

export const getComponentId = settings => {
  const id = settings?.id

  return id ? id : null
}

export const getComponentClass = settings => {
  const className = settings?.className

  return className ? className : ''
}

export const isInternalUrl = url => {
  const exp = new RegExp('^www.')
  const exp2 = new RegExp('^http(s)?://')

  return !exp.test(url) && !exp2.test(url)
}

export function getDestinationPartsValue() {
  let value = 0

  if (typeof window !== 'undefined') {
    if (window.innerWidth < 576) {
      value = 0
    } else if (window.innerWidth < 768) {
      value = 2
    } else if (window.innerWidth < 992) {
      value = 3
    } else if (window.innerWidth < 1200) {
      value = 4
    } else if (window.innerWidth < 1400) {
      value = 5
    } else if (window.innerWidth >= 1400) {
      value = 6
    }
  }

  return value
}
